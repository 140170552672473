<template>
        <div class="page-wrapper">
            <Header />


            <div class="app-content content">
                <div class="content-overlay"></div>
                <div class="header-navbar-shadow"></div>
                <div class="content-wrapper">
                    <router-view />
                </div>
            </div>


            <Footer />
        </div>
</template>

<script>
    import Footer from "./Footer";
    import Header from "./Header";

    export default {
        name: "index",
        components: {
            Footer,
            Header
        },
    }
</script>

<style scoped>

</style>
