var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('nav',{staticClass:"header-navbar navbar-expand-lg navbar navbar-with-menu floating-nav navbar-light navbar-shadow"},[_c('div',{staticClass:"navbar-wrapper"},[_c('div',{staticClass:"navbar-container content"},[_c('div',{staticClass:"navbar-collapse",attrs:{"id":"navbar-mobile"}},[_c('div',{staticClass:"mr-auto float-left bookmark-wrapper d-flex align-items-center"}),_c('ul',{staticClass:"nav navbar-nav float-right"},[_c('li',{staticClass:"dropdown dropdown-user nav-item"},[_c('a',{staticClass:"dropdown-toggle nav-link dropdown-user-link",attrs:{"href":"#","data-toggle":"dropdown"}},[_c('div',{staticClass:"user-nav d-sm-flex d-none"},[_c('span',{staticClass:"user-name text-bold-600"},[_vm._v(_vm._s(_vm.username))])]),_vm._m(0)]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":_vm.logoutUser}},[_c('i',{staticClass:"feather icon-power"}),_vm._v(" Logout")])])])])])])])]),_c('div',{staticClass:"main-menu menu-fixed menu-light menu-accordion menu-shadow",staticStyle:{"width":"260px !important"},attrs:{"data-scroll-to-active":"true"}},[_vm._m(1),_c('div',{staticClass:"main-menu-content"},[_c('ul',{staticClass:"navigation navigation-main",attrs:{"id":"main-menu-navigation","data-menu":"menu-navigation"}},[_c('router-link',{attrs:{"to":"/app/home"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isActive && 'active', isExactActive && 'router-link-exact-active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("Dashboard")])])]}}])}),_c('router-link',{attrs:{"to":"/app/getQuote"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isActive && 'active', isExactActive && 'router-link-exact-active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("Get Quote")])])]}}])}),_c('router-link',{attrs:{"to":"/app/policies"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isActive && 'active', isExactActive && 'router-link-exact-active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("My Policies")])])]}}])}),_c('router-link',{attrs:{"to":"/app/settings"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isActive && 'active', isExactActive && 'router-link-exact-active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("Settings")])])]}}])})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('img',{staticClass:"round",attrs:{"src":require("../../app-assets/images/user.png"),"alt":"avatar","height":"40","width":"40"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar-header mb-5"},[_c('ul',{staticClass:"nav navbar-nav flex-row"},[_c('li',{staticClass:"nav-item mr-auto"},[_c('a',{staticClass:"navbar-brand",attrs:{"href":"/app/home"}},[_c('img',{staticStyle:{"max-width":"150px"},attrs:{"src":require("@/client/assets/images/tia_logo.png"),"alt":""}})])])])])}]

export { render, staticRenderFns }