<template>
    <div>
        <!-- BEGIN: Header-->
        <nav class="header-navbar navbar-expand-lg navbar navbar-with-menu floating-nav navbar-light navbar-shadow">
            <div class="navbar-wrapper">
                <div class="navbar-container content">
                    <div class="navbar-collapse" id="navbar-mobile">
                        <div class="mr-auto float-left bookmark-wrapper d-flex align-items-center">

                        </div>
                        <ul class="nav navbar-nav float-right">


                            <li class="dropdown dropdown-user nav-item"><a class="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown">
                                <div class="user-nav d-sm-flex d-none"><span class="user-name text-bold-600">{{ username }}</span></div><span>
                                <img class="round" src="../../app-assets/images/user.png" alt="avatar" height="40" width="40">
                            </span>
                            </a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a @click="logoutUser" class="dropdown-item" href="#"><i class="feather icon-power"></i> Logout</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
        <!-- END: Header-->


        <!-- BEGIN: Main Menu-->
        <div style="width: 260px !important;" class="main-menu menu-fixed menu-light menu-accordion menu-shadow" data-scroll-to-active="true">
            <div class="navbar-header mb-5">
                <ul class="nav navbar-nav flex-row">
                    <li class="nav-item mr-auto">
                        <a class="navbar-brand" href="/app/home">
                            <img style="max-width: 150px;" src="@/client/assets/images/tia_logo.png" alt="">
                        </a>
                    </li>

                </ul>
            </div>

            <div class="main-menu-content">
                <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">


                    <router-link
                            to="/app/home"
                            v-slot="{ href, route, navigate, isActive, isExactActive }"
                    >
                        <li
                                :class="[isActive && 'active', isExactActive && 'router-link-exact-active']"
                        >
                            <a :href="href" @click="navigate">Dashboard</a>
                        </li>
                    </router-link>


                    <router-link
                            to="/app/getQuote"
                            v-slot="{ href, route, navigate, isActive, isExactActive }"
                    >
                        <li
                                :class="[isActive && 'active', isExactActive && 'router-link-exact-active']"
                        >
                            <a :href="href" @click="navigate">Get Quote</a>
                        </li>
                    </router-link>


                    <router-link
                            to="/app/policies"
                            v-slot="{ href, route, navigate, isActive, isExactActive }"
                    >
                        <li
                                :class="[isActive && 'active', isExactActive && 'router-link-exact-active']"
                        >
                            <a :href="href" @click="navigate">My Policies</a>
                        </li>
                    </router-link>

                    <!--<router-link

                            to="/app/trips"
                            v-slot="{ href, route, navigate, isActive, isExactActive }"
                    >
                        <li
                                :class="[isActive && 'active', isExactActive && 'router-link-exact-active']"
                        >
                            <a :href="href" @click="navigate">Trips</a>
                        </li>
                    </router-link>-->

                    <!--<router-link
                            to="/app/makeAclaim"
                            v-slot="{ href, route, navigate, isActive, isExactActive }"
                    >
                        <li
                                :class="[isActive && 'active', isExactActive && 'router-link-exact-active']"
                        >
                            <a :href="href" @click="navigate">Make A Claim</a>
                        </li>
                    </router-link>-->

                    <router-link
                            to="/app/settings"
                            v-slot="{ href, route, navigate, isActive, isExactActive }"
                    >
                        <li
                                :class="[isActive && 'active', isExactActive && 'router-link-exact-active']"
                        >
                            <a :href="href" @click="navigate">Settings</a>
                        </li>
                    </router-link>

                </ul>
            </div>
        </div>
        <!-- END: Main Menu-->
    </div>
</template>

<script>

    export default {
        name: "Header",
        data () {
            return {
                username:""
            }
        },
        methods:{
            logoutUser() {
                localStorage.removeItem("token");

                this.$router.push("/login");
            },
        },
        mounted(){
            this.username = `${localStorage.getItem("name") || ''} ${localStorage.getItem("surname") || ''}`
        }
    }
</script>



