<template>
    <div>



        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>

        <!-- BEGIN: Footer
        <footer class="footer footer-static footer-light">
            <p class="clearfix blue-grey lighten-2 mb-0">
                © 2023 Switch. All Rights Reserved. Regulated by the IRA. <a href="#">Terms of Use / Privacy Policy</a>
                <button class="btn btn-primary btn-icon scroll-top" type="button"><i class="feather icon-arrow-up"></i></button>
            </p>
        </footer>-->
    </div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>

</style>
